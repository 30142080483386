<template>
  <div class="contentView orderManage">
    <div class="centerView">
      <a-form ref="filterFormRef" :model="filterForm" layout="inline">
        <a-form-item name="detailSearchContent">
          <a-input v-model:value.trim="filterForm.detailSearchContent" allowClear size="large" :placeholder="t('orderManage.filterForm.detailSearchContent')" />
        </a-form-item>
        <a-form-item name="dissentReceiptAtStart">
          <a-range-picker v-model:value="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :showTime="showTime" :placeholder="['Begin Time', 'End Time']" size="large">
            <template #suffixIcon>
              <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item name="paymentType">
          <a-select v-model:value="filterForm.paymentType" :placeholder="t('paymentDeductBill.filterForm.paymentType')" size="large" :style="{ width: '188px' }">
            <a-select-option :value="item.value" v-for="item in paymentTypes" :key="item.value">{{ t(item.label) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-space :size="16">
            <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
            <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
          </a-space>
        </a-form-item>
      </a-form>
    </div>

    <div class="centerView">
      <div class="manageNav">
        <a-tabs v-model:activeKey="activeKey" size="small">
          <a-tab-pane v-for="item in tabsPaneList" :key="item.key" :tab="`${t(item.tab)}(${item.num})`"></a-tab-pane>
        </a-tabs>
        <div class="btnExport">
          <a-button type="primary" size="large" :disabled="selectedRowKeysList.length > 0 ? false : true" @click="exportClick">导出订单明细</a-button>
        </div>
      </div>
      <a-spin tip="Loading..." :spinning="spinRef">
        <a-table
          v-if="!tableLoading"
          :dataSource="dataSource"
          :columns="columns"
          :loading="tableLoading"
          :pagination="false"
          row-key="id"
          size="small"
          class="ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :scroll="{ x: 1300 }"
          :row-selection="rowSelection"
        >
          <template #productPicList="{ record, text }">
            <ImgGallery v-if="text && text.length" :urls="text" @viewMore="toDetail('view', 'receive', record)" />
            <span v-else>-</span>
          </template>
          <template #orderSn="{ record, text }">
            <a-space>
              <span style="color: #050505; text-decoration: underline; cursor: pointer" @click="toDetail('view', 'receive', record)">{{ text || '-' }}</span>
              <!-- 紧急标识 (0:普通，1:紧急) -->
              <!-- <img v-if="record.importantFlag == 1" style="width: 20px; height: 20px; margin-left: 10px" src="@/assets/supplier/images/orderManage/urgent-icon.png" /> -->
            </a-space>
          </template>
          <template #paymentType="{ record }">
            {{ record.paymentTypeValue }}
          </template>
          <template #dissentReceiptAt="{ record, text }">
            {{ text || '-' }}
            <br />
            <!-- <span class="over-date" v-if="text && ['2'].includes(activeKey) && overDateFn(record) > 0">{{ t('orderManage.table.overdue') }} {{ overDateFn(record) }} {{ t('orderManage.table.day') }}</span> -->
            <span class="over-date" v-if="text && ['2'].includes(activeKey) && record.exceedDays">
              {{ t('orderManage.table.overdue') }} {{ record.exceedDays }} {{ t('orderManage.table.day') }}
            </span>
          </template>
          <template #cancelAt="{ text }">
            <span>{{ text || '-' }}</span>
          </template>
          <template #finishAt="{ record, text }">
            <span :style="{ color: record.dissentReceiptAt != text ? '#ff6700' : '' }">{{ text }}</span>
          </template>
          <!-- 只取实付金额 -->
          <template #amount="{ record }">￥{{ record.dissentAmount || 0 }}</template>
          <template #expressFees="{ record }">￥{{ record.expressFees || 0 }}</template>
          <!-- <template #dissentAmount="{ record, text }">
          <span :style="{ color: record.amount != text ? '#ff6700' : '' }">￥{{ text || 0 }}</span>
        </template> -->
          <template #quantity="{ record }">
            {{ record.dissentQuantity || 0 }}
          </template>
          <template #deliveryQuantity="{ record, text }">
            <span :style="{ color: activeKey == '3' && text && record.dissentQuantity != text ? '#ff6700' : '' }">{{ text || 0 }}</span>
          </template>
          <template #receiptQuantity="{ text }">
            {{ text }}
          </template>
          <template #status="{ text }">
            <span :style="{ color: text && text == 11 ? '#F53838' : text && text == 12 ? '#4FD538' : '' }">
              {{ (text && t(typeList.find(it => it.value == text).label)) || '-' }}
            </span>
          </template>
          <template #shippingCount="{ record, text }">
            <span v-if="text == 0">{{ t('orderManage.table.shippingCountTips1') }} 0 {{ t('orderManage.table.shippingCountTips2') }}</span>
            <span v-else @click="toDetail('view', 'delivery', { ...record, activeKey: '2' })" style="cursor: pointer">
              {{ t('orderManage.table.shippingCountTips1') }}
              <span style="color: #ff6700">{{ text }}</span>
              {{ t('orderManage.table.shippingCountTips2') }}
            </span>
          </template>
          <template #cancelReason="{ text }">
            {{ text || '-' }}
          </template>

          <template #typeValue="{ text }">
            {{ text }}
          </template>
          <template #remark="{ text }">
            {{ text }}
          </template>

          <template #operate="{ text }">
            <a-space :size="20">
              <a-button type="primary" v-if="activeKey == '1'" @click="toDetail('add', 'receive', text)" :disabled="text.status == 11">
                {{ t('orderManage.table.receive') }}
              </a-button>
              <!-- 待发订单 状态 当采购类型是打版采购、样衣采购时，不显示【备货】按钮 -->
              <a-button
                type="primary"
                v-if="activeKey == '2' && text?.type !== 90 && text?.type !== 60"
                :disabled="!(!text.deliveryQuantity || text.dissentQuantity > text.deliveryQuantity)"
                @click="toDetail('add', 'delivery', text)"
              >
                {{ t('orderManage.table.delivery') }}
              </a-button>
              <a-button type="primary" v-if="['3', '4'].includes(String(activeKey))" @click="getLog(text)">{{ t('orderManage.table.log') }}</a-button>
              <a-button
                type="primary"
                v-if="activeKey == '2'"
                :disabled="!(!text.deliveryQuantity || text.dissentQuantity > text.deliveryQuantity)"
                @click="toShopingDetail('add', 'delivery', text)"
              >
                {{ t('orderManageDetail.DeliveryTable.oneClickDelivery') }}
              </a-button>
            </a-space>
          </template>
        </a-table>
      </a-spin>
      <TableSkeleton v-if="tableLoading" />

      <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination>
    </div>
    <!-- <a-modal v-model:visible="visibleDetail" title="Basic Modal">
      <detailCpn ref="manageDetailRef" />
    </a-modal> -->
    <a-drawer v-model:visible="visibleDetail" width="90% !important" class="custom-class" title="" placement="right">
      <detailCpn ref="manageDetailRef" @closeModal="closeModal" @closeDrawerUpdate="closeDrawerUpdate" />
    </a-drawer>
    <!-- 日志 -->
    <OperationLogModal v-model:modalVisible="operationLogModalVisible" :data="operationLogData" />
  </div>
</template>

<script setup>
  /* eslint-disable */
  import { ref, watch, onActivated, onMounted, computed, unref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { message } from 'ant-design-vue'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { transformColumnsI18n, filterInitialColumnsFn } from '@/language'
  import pagination from '@/components/pagination'
  import TableSkeleton from '@/views/supplier/components/TableSkeleton'
  import ImgGallery from '@/views/supplier/components/ImgGallery'
  import OperationLogModal from '@/views/supplier/components/OperationLogModal'
  import detailCpn from './orderManageDetail.vue'
  import { initialColumns, returnTableColumn } from './config/orderManageTableList'
  import { getOrderManageTableData, getOperationLog, getOrderManageTotalsData } from '@/api/supplier/orderManagement/orderManage'
  import { createExportTask, getTaskResult } from '@/api/supplier/data-workers'

  // export default {
  //   name: 'orderManage',
  //   components: {
  //     pagination,
  //     TableSkeleton,
  //     ImgGallery,
  //     OperationLogModal,
  //   },
  //   setup() {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const { t, locale } = useI18n()
  const visibleDetail = ref(false) //详情页面
  const manageDetailRef = ref() //绑定详情元素
  const spinRef = ref(false)
  /*  */
  const activeKey = ref('1')
  // 10待接订单  11异议订单 12确议订单  30待发货订单 40完成订单 50取消订单
  const tabsPaneList = ref([
    {
      index: [10, 11, 12],
      key: '1',
      tab: 'orderManage.tabsPane.1',
      num: 0,
      flag: 'waitAcceptCount'
    },
    {
      index: [30],
      key: '2',
      tab: 'orderManage.tabsPane.2',
      num: 0,
      flag: 'waitShippingCount'
    },
    {
      index: [40],
      key: '3',
      tab: 'orderManage.tabsPane.3',
      num: 0,
      flag: 'finishedCount'
    },
    {
      index: [50],
      key: '4',
      tab: 'orderManage.tabsPane.4',
      num: 0,
      flag: 'cancelCount'
    }
  ])

  function getOrderManageTotalsDataFn() {
    getOrderManageTotalsData({})
      .then(res => {
        if (res.code === 200) {
          tabsPaneList.value = tabsPaneList.value.map(item => {
            item.num = res.data[item.flag] || 0
            return item
          })
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {})
  }
  // getOrderManageTotalsDataFn();

  /*  */
  const filterFormRef = ref()
  const initialFilterForm = {
    detailSearchContent: '',
    dissentReceiptAtStart: '',
    dissentReceiptAtEnd: '',
    statusList: [10, 11, 12],
    paymentType: null,
    page: 1,
    size: 10
  }
  const filterForm = ref({ ...initialFilterForm })
  const date = ref([])

  const showTime = {
    hideDisabledOptions: true,
    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
  }
  /* 付款方式 */
  const paymentTypes = [
    {
      label: 'orderManage.paymentTypes.1',
      value: 1
    },
    {
      label: 'orderManage.paymentTypes.2',
      value: 2
    },
    {
      label: 'orderManage.paymentTypes.3',
      value: 3
    }
  ]
  /* 待接订单状态 */
  const typeList = [
    {
      label: 'orderManage.typeList.1',
      value: 10
    },
    {
      label: 'orderManage.typeList.2',
      value: 11
    },
    {
      label: 'orderManage.typeList.3',
      value: 12
    }
  ]
  /*  */

  const columns = ref([])
  watch(locale, () => {
    let keys = columns.value.map(({ key }) => key)
    columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, keys))
  })

  const tableLoading = ref(false)
  const dataSource = ref([])
  const tableResult = ref()

  function getTableData() {
    tableLoading.value = true
    getOrderManageTotalsDataFn()
    tableResult.value = {}
    dataSource.value = []
    const [dissentReceiptAtStart = null, dissentReceiptAtEnd = null] = date.value || []
    let statusList = JSON.parse(JSON.stringify(filterForm.value.statusList))
    // filterForm.value.statusList = filterForm.value.statusList.join();
    getOrderManageTableData({ ...filterForm.value, statusList: statusList.join(), dissentReceiptAtStart, dissentReceiptAtEnd })
      .then(res => {
        tableLoading.value = false
        if (res.code === 200) {
          tableResult.value = res.data
          dataSource.value = tableResult.value.data
          // tabsPaneList.value.find((it) => it.key == activeKey.value).num = res.data.total;
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {
        tableLoading.value = false
      })
  }
  const closeDrawerUpdate = () => {
    visibleDetail.value = false
    getTableData()
  }
  function reset() {
    filterForm.value = { ...filterForm.value, ...initialFilterForm }
    date.value = []
    if (activeKey.value == '1') getTableData()
    activeKey.value = '1'
  }
  function search() {
    filterForm.value.page = 1
    getTableData()
  }
  function onPageChange(e) {
    filterForm.value.page = e
    getTableData()
  }
  function onSizeChange({ pageSize }) {
    filterForm.value.size = pageSize
    filterForm.value.page = 1
    getTableData()
  }

  watch(
    () => activeKey.value,
    newValue => {
      filterColumns(newValue)
    }
  )

  function filterColumns(newValue) {
    filterForm.value.statusList = tabsPaneList.value.find(it => it.key == newValue).index
    let filterKeys = returnTableColumn(newValue)
    columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, filterKeys))
    search()
  }

  onMounted(() => {
    if (route.meta.isBack) {
      filterColumns(activeKey.value)
    }
  })

  onActivated(() => {
    if (!route.meta.isBack) {
      // getOrderManageTotalsDataFn();
      filterColumns(activeKey.value)
    }
    if (route.meta.isBack && route.meta.isEdit) {
      // getOrderManageTotalsDataFn();
      filterColumns(activeKey.value)
    }
    route.meta.isBack = false
    route.meta.isEdit = false
  })

  function overDateFn({ dissentReceiptAt, finishAt }) {
    if (!dissentReceiptAt) return 0
    dissentReceiptAt = moment(dissentReceiptAt).format('YYYY-MM-DD')
    const endDate = activeKey.value == '2' ? Date.now() : finishAt || Date.now()
    // const endDate = activeKey.value == '2' ? moment().format('YYYY-MM-DD') : moment(finishAt).format('YYYY-MM-DD');
    return moment(moment(endDate).format('YYYY-MM-DD')).diff(dissentReceiptAt, 'day')
  }

  const operationLogModalVisible = ref(false)
  const operationLogData = ref([])
  const getLogging = ref(false)
  function getLog({ id }) {
    getLogging.value = true
    // businessType 业务类型（1：订单,2:退供应商订单）
    getOperationLog({ objectId: id, businessType: 1 })
      .then(res => {
        getLogging.value = false
        if (res.code == 200) {
          operationLogData.value = res.data || []
          if (!operationLogData.value.length) {
            message.info(t('noData'))
          } else {
            operationLogModalVisible.value = true
          }
        } else {
          message.error(res.message)
        }
      })
      .catch(() => {
        getLogging.value = false
      })
  }

  function toDetail(type, tableType, { id = '', activeKey = '' } = {}) {
    // router.push({ name: 'orderManageDetail', query: { type, tableType, id, activeKey } })
    visibleDetail.value = true
    setTimeout(() => {
      manageDetailRef.value.getDetailDataFn(type, tableType, id)
    }, 500)
  }
  //一键发货
  const toShopingDetail = (type, tableType, { id = '', activeKey = '' } = {}) => {
    router.push({ name: 'shippingDetails', query: { type, tableType, id, activeKey } })
  }

  // 选中的行key列表
  const selectedRowKeysList = ref([])
  // 选中的行数据
  const selectedRowList = ref([])
  const rowSelection = computed(() => {
    return {
      // getCheckboxProps: record => ({
      //   disabled: dataSource.value.some(it => it.attributeId == record.id),
      // }),
      fixed: true,
      selectedRowKeys: unref(selectedRowKeysList),
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows, 'selectedRowKeys, selectedRows------selectedRowKeys, selectedRows')
        selectedRowKeysList.value = selectedRowKeys
        selectedRowList.value = selectedRows
      },
      hideDefaultSelections: true
    }
  })
  //点击导出订单明细
  const downloadToken = ref() //导出明细token
  const exportClick = async () => {
    downloadToken.value = ''
    let selelist = {
      purchaseOrderIds: selectedRowKeysList.value.map(String)
    }
    let payload = {
      projectCode: 'SUPPLIER_ORDER_DETAIL_EXPORT',
      dataParam: JSON.stringify(selelist)
    }
    const { code, data } = await createExportTask(payload)
    if (code == 200) downloadToken.value = data
    spinRef.value = true
    await getTaskResultFn(data)
  }
  const countNum = ref('')
  //下载列表轮训
  const getTaskResultFn = () => {
    getTaskResult({ taskToken: downloadToken.value })
      .then(res => {
        if (res.code == 200) {
          // 任务状态
          //   0、待处理
          //   1、成功
          //   9、失败
          if (countNum.value > 5 && res.data.state == 0) {
            countNum.value = 0
            spinRef.value = false
            return message.error('下载失败，请稍后重试')
          }
          switch (res.data.state) {
            case 0:
              countNum.value++
              setTimeout(getTaskResultFn, 2000)
              break
            case 1:
              countNum.value = 0
              spinRef.value = false
              selectedRowKeysList.value = []
              selectedRowList.value = []
              res.data.fileUrl && (window.location.href = res.data.fileUrl)
              break
            case 9:
              countNum.value = 0
              spinRef.value = false
              message.error(res.data.resultMessage || '下载失败')
              break
            default:
              break
          }
        }
      })
      .catch(() => {
        countNum.value = 0
        spinRef.value = false
      })
  }
  const closeModal = () => {
    visibleDetail.value = false
    router.push({
      path: '/supplier-system/orderManagement/deliveryManage'
    })
  }
  //   return {
  //     t,
  //     filterForm,
  //     date,
  //     showTime,
  //     activeKey,
  //     tabsPaneList,
  //     columns,
  //     dataSource,
  //     tableResult,
  //     tableLoading,
  //     typeList,
  //     paymentTypes,
  //     search,
  //     reset,
  //     onPageChange,
  //     onSizeChange,
  //     overDateFn,
  //     operationLogModalVisible,
  //     operationLogData,
  //     getLogging,
  //     getLog,
  //     toDetail,
  //     toShopingDetail,
  //     selectedRowKeysList,
  //     selectedRowList,
  //     rowSelection,
  //     exportClick
  //   }
  // },
  // }
</script>

<style lang="less">
  .manageNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .orderManage {
    .ant-table-wrapper {
      margin-bottom: 10px;
    }
    .ant-table-striped :deep(.table-striped) td {
      background-color: #fafafa;
    }
    .over-date {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 5px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      border-radius: 10px;
      background-color: #f53838;
      white-space: nowrap;
    }
  }
</style>
<style lang="less">
  // @import url('/src/assets/supplier/css/custom.less');
</style>
